import iconDependency from '@/dependencies/iconDependency'

export const leftMenu = () => {
  const iconSvg = iconDependency()
  return [
    {
      name: 'BERANDA',
      items: [
        {
          icon: iconSvg.home,
          label: 'Dashboard',
          route: 'dashboard',
          items: [],
          prohibitedRoles: []
        }, {
          icon: iconSvg.contract,
          label: 'Kontrak',
          route: 'contract',
          prohibitedRoles: []
        }, {
          icon: iconSvg.transaction,
          label: 'Transaksi',
          route: 'transaction',
          prohibitedRoles: []
        }, {
          icon: iconSvg.termsOfPayment,
          label: 'Terms Of Payment',
          route: 'terms-of-payment',
          prohibitedRoles: []
        }, {
          icon: iconSvg.report,
          label: 'Laporan',
          route: 'reports',
          prohibitedRoles: []
        }
      ],
      prohibitedRoles: []
    },
    {
      name: 'Transaksi',
      items: [
        {
          icon: iconSvg.approveDeposit,
          label: 'Transaksi',
          route: 'transaction',
          prohibitedRoles: []
        },
      ],
      prohibitedRoles: []
    },
    {
      name: 'PERSIAPAN KEBERANGKATAN',
      items: [
        {
          icon: iconSvg.masterRoute,
          label: 'Master Rute',
          route: 'master-route',
          prohibitedRoles: []
        }
      ],
      prohibitedRoles: []
    },
    {
      name: 'SETTING',
      items: [{
        icon: iconSvg.settings,
        label: 'Pengaturan',
        route: null,
        items: [{
          label: 'Access Keys',
          route: 'access-keys',
          prohibitedRoles: []
        },
        {
          label: 'Akun',
          route: 'setting-account',
          prohibitedRoles: []
        },
        {
          label: 'Notifikasi Email',
          route: 'email-notification',
          prohibitedRoles: []
        },
        ],
        prohibitedRoles: []
      }],
      prohibitedRoles: []
    },
    {
      name: 'MASTER',
      items: [{
        icon: iconSvg.settings,
        label: 'Master Settings',
        route: null,
        items: [{
          label: 'Master Bank',
          route: 'master-bank',
          prohibitedRoles: []
        },
        ],
        prohibitedRoles: []
      }],
      prohibitedRoles: []
    },
    {
      name: 'SETTINGS',
      items: [
        {
          icon: iconSvg.globalSettings,
          label: 'Global Settings',
          route: 'global-settings',
          prohibitedRoles: []
        },
      ],
      prohibitedRoles: []
    },
  ]
}

export const leftMenuProduction = () => {
  const iconSvg = iconDependency()
  return [
    {
      name: 'BERANDA',
      items: [
        {
          icon: iconSvg.home,
          label: 'Dashboard',
          route: 'dashboard',
          items: [],
          prohibitedRoles: []
        }
      ],
      prohibitedRoles: []
    },
    {
      name: 'Kontrak',
      items: [
        {
          icon: iconSvg.contract,
          label: 'Kontrak',
          route: 'contract',
          prohibitedRoles: []
        },
      ],
      prohibitedRoles: []
    },
    {
      name: 'Transaksi',
      items: [
        {
          icon: iconSvg.approveDeposit,
          label: 'Transaksi',
          route: 'transaction',
          prohibitedRoles: []
        },
      ],
      prohibitedRoles: []
    },
    {
      name: 'Terms of Payment',
      items: [
        {
          icon: iconSvg.termsOfPayment,
          label: 'Tagihan',
          route: 'terms-of-payment',
          prohibitedRoles: []
        }
      ],
      prohibitedRoles: []
    },
    // {
    //   name: 'SETTING',
    //   items: [{
    //     icon: iconSvg.globalSettings,
    //     label: 'Pengaturan',
    //     route: null,
    //     items: [{
    //       label: 'Access Keys',
    //       route: 'access-keys',
    //       prohibitedRoles: []
    //     },
    //     {
    //       label: 'Akun',
    //       route: 'setting-account',
    //       prohibitedRoles: []
    //     },
    //     {
    //       label: 'Notifikasi Email',
    //       route: 'email-notification',
    //       prohibitedRoles: []
    //     },
    //     ],
    //     prohibitedRoles: []
    //   }],
    //   prohibitedRoles: []
    // },
  ]
}

export const leftMenuStaging = () => {
  const iconSvg = iconDependency()
  return [
    {
      name: 'BERANDA',
      items: [
        {
          icon: iconSvg.home,
          label: 'Dashboard',
          route: 'dashboard',
          items: [],
          prohibitedRoles: []
        }
      ],
      prohibitedRoles: []
    },
    {
      name: 'Kontrak',
      items: [
        {
          icon: iconSvg.contract,
          label: 'Kontrak',
          route: 'contract',
          prohibitedRoles: []
        },
      ],
      prohibitedRoles: []
    },
    {
      name: 'Transaksi',
      items: [
        {
          icon: iconSvg.approveDeposit,
          label: 'Transaksi',
          route: 'transaction',
          prohibitedRoles: []
        },
      ],
      prohibitedRoles: []
    },
    {
      name: 'Terms of Payment',
      items: [
        {
          icon: iconSvg.termsOfPayment,
          label: 'Tagihan',
          route: 'terms-of-payment',
          prohibitedRoles: []
        }
      ],
      prohibitedRoles: []
    },
    // {
    //   name: 'SETTING',
    //   items: [{
    //     icon: iconSvg.globalSettings,
    //     label: 'Pengaturan',
    //     route: null,
    //     items: [{
    //       label: 'Access Keys',
    //       route: 'access-keys',
    //       prohibitedRoles: []
    //     },
    //     {
    //       label: 'Akun',
    //       route: 'setting-account',
    //       prohibitedRoles: []
    //     },
    //     {
    //       label: 'Notifikasi Email',
    //       route: 'email-notification',
    //       prohibitedRoles: []
    //     },
    //     ],
    //     prohibitedRoles: []
    //   }],
    //   prohibitedRoles: []
    // },
    {
      name: 'DEVELOPER SETTING',
      items: [{
        icon: iconSvg.settings,
        label: 'Developer Setting',
        route: null,
        items: [
          {
            label: 'Penggunaan API',
            route: 'api-usage',
            prohibitedRoles: []
          },
          {
            label: 'FAQ',
            route: 'faq',
            prohibitedRoles: []
          },
        ],
        prohibitedRoles: []
      }],
      prohibitedRoles: []
    },
  ]
}
