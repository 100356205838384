/* eslint-disable class-methods-use-this */
// import networkBoundResource from '@/data/networkBoundResource'
import store from '@/store'
import { Response } from '@/utils/helpers/response'

class Repository {
  remote: any

  sourceDb: any

  savingData: any

  constructor(remote: any, sourceDb: any, savingData: any = null) {
    this.remote = remote
    this.sourceDb = sourceDb
    if (savingData) {
      this.savingData = savingData
    }
  }

  private result = async (haveLocal: boolean) => {
    const data = new Response()
    // if (store.state.network.connection) {
    try {
      const fetch = await this.fetchData().run()
      const code = fetch.status
      const result = fetch.data
      if (code === 201) {
        data.result = fetch.data
        data.message = fetch.data.detail
      } else if (code === 200) {
        // eslint-disable-next-line no-prototype-builtins
        if (typeof result.Data === 'object' || Array.isArray(result.Data)) {
          data.result = result.Data
          data.count = result.Count
          data.pagesize = result.PageSize
          data.pagenumber = result.PageNumber
          data.message = result.title && result.detail ? `${result.title} ${result.detail}` : 'berhasil ditampilkan'
        } else {
          data.message = `${result.title} ${result.detail}`
          data.result = result
        }
      } else {
        data.error = true
        data.message = `${fetch.data.title} ${fetch.data.detail}`
        data.result = fetch.data.Data
      }
      data.code = fetch.status
    } catch (e: any) {
      if (typeof e.response === 'undefined') {
        data.error = true
        data.errors = ''
        data.message = 'A network error occurred. This could be a CORS issue or a dropped internet connection. It is not possible for us to know.'
        data.result = ''
        // return Promise.reject(e)
        return data
      }
      if (e.response.status === 400) {
        data.error = true
        data.errors = e.response.errors
        data.message = `${e.response.data.title} ${e.response.data.detail}`
        data.code = (e.response) ? e.request.status : 400
      } else if (e.response.status === 401) {
        data.error = true
        data.errors = e.response.errors
        data.message = `${e.response.data.title} ${e.response.data.detail}`
        data.code = (e.response) ? e.request.status : 401
      } else if (e.response.status === 502) {
        data.error = true
        data.errors = e.response.errors
        data.message = 'Cek koneksi internet anda'
        data.code = (e.response) ? e.request.status : 502
      } else {
        data.error = true
        data.errors = e.response.errors
        data.message = 'Gagal mengambil data'
        data.code = (e.response) ? e.request.status : 500
      }
    }
    if (haveLocal) {
      this.saveResultData(data)
    }
    // } else if (haveLocal) {
    //   data.result = await this.loadFromDB()
    // } else {
    //   store.dispatch('setNoInternet', true)
    // }

    return data
  }

  loadFromDB() {
    return this.sourceDb
  }

  fetchData() {
    return this.remote
  }

  saveResultData(data: any): void {
    this.sourceDb.changeData(data)
    this.loadFromDB().run()
    // console.log('save result data', data)
  }

  getResult = async (haveLocal: boolean): Promise<Response> => {
    const data = await this.result(haveLocal)
    return data
  }
}

export default Repository
